import React, { useEffect, useState } from "react"

import Layout from "../../../structure/Layout/Layout"
import Split from "../../../structure/Split/Split"
import Seo from "../../../structure/Seo/Seo"
import Illustration from './components/Left/Illustration'
import Welcome from "./components/Right/Welcome"

import "./styles.scss";
import { clearLSFilters } from "../../../../utils/Data"

const IndexPage = () => {
  const [path, setPath] = useState("");

  useEffect(() => {
      clearLSFilters();
  }, []);

  return (
    <Layout current='home'>
      <Seo
        title="Bienvenue sur Vu des Quartiers"
        description={`"Vu des Quartiers" est un annuaire de référents dans les quartiers prioritaires, habitants et contacts, pour renouer un lien avec la presse et faire remonter l’information directement des quartiers. "Vu des Quartiers" est une initiative cofondée par l’association des maires Ville & Banlieue et BFMTV, et gérée par des rédactions de presse.`} 
      />
      <div className="page page-home">
        <Split
          left={<Illustration />}
          right={<Welcome />}
          skyline={true}
        />
      </div>
    </Layout>
  );
}

export default IndexPage