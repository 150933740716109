import React from "react"
import Link from 'gatsby-plugin-transition-link';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import ButtonIcon from "../../../../../element/Buttons/ButtonIcon"
import { FaArrowUp, FaArrowRight, FaArrowLeft, FaSortAlphaDown, FaSortAlphaDownAlt } from 'react-icons/fa';
import { isLoggedIn } from "../../../../../../services/AuthService";

import "./styles.scss";


const Welcome = ({ }) => {
  return (
    <>
      <div className="split_welcome">
        <div className="wrapp">
          <div className="wrapp-content">
            {!!isLoggedIn() ?
              <div className="inner_1">
                <div className="inner__intro">
                  <div className="title">
                    <h1>
                      <span>Explorez l’annuaire</span>
                      <b>« Vu des Quartiers »</b>
                    </h1>
                    <svg className="svg svg-magnifying-glass">
                      <use xlinkHref="#magnifyingGlass"></use>
                    </svg>
                  </div>
                  <p className="detail">
                    Trouvez les contacts qui s’investissent au quotidien pour dynamiser les quartiers prioritaires de la ville par lieux, thèmes, mots-clés…
                  </p>
                </div>
                <AniLink
                  fade
                  entry={{ delay: 0.25 }}
                  to={'/annuaire'}
                  className="btn btn-cta btn-primary invert rounded">
                  <span className="btn_label">Rechercher un interlocuteur pour un article, un reportage…</span>
                  <span className="btn_icon">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </AniLink>
              </div>
              :
              <div className="inner_1">
                <div className="inner__intro">
                  <div className="title">
                    <h1>
                      <span>Rejoignez notre annuaire</span>
                      <b>et devenez interlocuteur<br />pour votre quartier !</b>
                    </h1>
                    <svg className="svg svg-home-join">
                      <use xlinkHref="#homeJoin"></use>
                    </svg>
                  </div>
                  <p className="detail">
                    L’annuaire met en valeur les contacts d’habitants et acteurs des quartiers souhaitant <b>échanger avec la presse et raconter ce qui se passe chez eux.</b>
                  </p>
                </div>
                <AniLink
                  fade
                  entry={{ delay: 0.25 }}
                  to={'/membre'}
                  className="btn btn-cta btn-primary invert rounded">
                  <span className="btn_label">Devenir interlocuteur pour mon quartier</span>
                  <span className="btn_icon">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </AniLink>
              </div>
            }

            <div className="inner_2">
              <div className="inner__intro">
                <h2>
                  <span>Découvrez le portrait des</span>
                  <b>1 514 quartiers prioritaires de la ville</b>
                </h2>
              </div>
              <div className="btn__intro">
                <AniLink
                  fade
                  entry={{ delay: 0.25 }}
                  to={'/portrait-national'}
                  className="btn btn-cta btn-quaternary-25 rounded">
                  <span className="btn_label">Découvrir les quartiers à l’échelle de la France métropolitaine</span>
                  <span className="btn_icon">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </AniLink>
              </div>
              <div className="inner__link">
                <AniLink
                  fade
                  entry={{ delay: 0.25 }}
                  to={'/portrait-region'}
                  className="btn btn-cta btn-quaternary-25 rounded">
                  <span className="btn_label">Par région</span>
                  <span className="btn_icon">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </AniLink>
                <AniLink
                  fade
                  entry={{ delay: 0.25 }}
                  to={'/portrait-outre-mer'}
                  className="btn btn-cta btn-quaternary-25 rounded">
                  <span className="btn_label">En Outre-mer</span>
                  <span className="btn_icon">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </AniLink>
                <AniLink
                  fade
                  entry={{ delay: 0.25 }}
                  to={'/portrait-departement'}
                  className="btn btn-cta btn-quaternary-25 rounded">
                  <span className="btn_label">Par département</span>
                  <span className="btn_icon">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </AniLink>
                <AniLink
                  fade
                  entry={{ delay: 0.5 }}
                  to={'/portrait-qpv'}
                  className="btn btn-cta btn-quaternary-25 rounded">
                  <span className="btn_label">Par quartier</span>
                  <span className="btn_icon">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </AniLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Welcome