import React from "react"

import "./styles.scss";

const Illustration = () => {
    return (
        <div className="split_illustration"></div>
    );
}

export default Illustration