const getDepartment = (postalCode: string): string => {
  return postalCode.match(/^97\d{3}/) ? postalCode.substr(0, 3) : postalCode.substr(0, 2);
}

const uniqBy = (a: any, key: any) => {
  let seen = new Set();
  return a.filter((item: any) => {
      let k = key(item);
      return seen.has(k) ? false : seen.add(k);
  });
}

const defaultFilters = {
  location: {
    region: [],
    department: [],
    city: [],
    qpv: []
  },
  radius: 10,
  geolocation: {
    latitude: null,
    longitude: null
  },
  types: [],
  thematics: []
};

const clearLSFilters = () => {
  if(!!window.localStorage && window.localStorage.getItem('filters')){
    window.localStorage.setItem('filters', JSON.stringify(defaultFilters));
  }
}

export { getDepartment, uniqBy, defaultFilters, clearLSFilters };